<template>
  <div>
    <div class="wrap-banner">
      <img
        class="banner"
        src="https://sms-1300492491.cos.ap-beijing.myqcloud.com/banners/banner04.png"
      />
    </div>
    <div class="wrap-content">
      <van-row type="flex" justify="center">
        <van-col class="wrap-form" span="22">
          <!-- <div>放生规格</div> -->
          <van-row class="content-form" type="flex" justify="space-between">
            <van-col class="left" offset="1" span="6">放生规格</van-col>
            <van-col span="14">
              <div class="right-specs">
                <div
                  :class="abc == index ? 'rightred':'rightdefault'"
                  v-for="(item,index) in production"
                  :key="index"
                  @click="getid(index)"
                >{{item.specname}}</div>
              </div>
            </van-col>
            <van-col span="1"></van-col>
          </van-row>
          <van-form @submit="onSubmit" ref="formData">
            <!-- <div>放生金额</div> -->
            <van-row class="content-form" type="flex" justify="space-between">
              <van-col class="left" offset="1" span="6">放生金额</van-col>
              <van-col class="right" span="14">
                <van-field
                  v-model="price"
                  :rules="[{ pattern, message: '请输入正确金额' }] "
                  placeholder="请输入金额（必填）"
                />
              </van-col>
              <van-col span="1"></van-col>
            </van-row>
            <!-- <div>功德芳名</div> -->
            <van-row class="content-form" type="flex" justify="space-between">
              <van-col class="left" offset="1" span="6">功德芳名</van-col>
              <van-col class="right" span="14">
                <van-field v-model="name" placeholder="请输入姓名（选填）" />
              </van-col>
              <van-col span="1"></van-col>
            </van-row>
            <!-- <div>联系电话</div> -->
            <van-row class="content-form" type="flex" justify="space-between">
              <van-col class="left" offset="1" span="6">联系电话</van-col>
              <van-col class="right" span="14">
                <van-field v-model="phone" placeholder="请输入电话（选填）" />
              </van-col>
              <van-col span="1"></van-col>
            </van-row>
            <!-- <div>祈福心愿</div> -->
            <van-row class="content-form" type="flex" justify="space-between">
              <van-col class="left" offset="1" span="6">祈福心愿</van-col>
              <van-col class="wished" span="14">
                <div>
                  <van-field
                    v-model="wish"
                    rows="3"
                    autosize
                    type="textarea"
                    maxlength="50"
                    placeholder="请输入祈愿内容（选填）"
                    show-word-limit
                    label-align="right"
                    colon
                  />
                </div>
              </van-col>
              <van-col span="1"></van-col>
            </van-row>
          </van-form>
          <!-- <div>放生按钮</div> -->
          <van-row type="flex" justify="center">
            <van-col span="9" class="btn">
              <van-button color="#bb5548" round size="large" @click="generate">放生</van-button>
            </van-col>
          </van-row>
        </van-col>
      </van-row>
    </div>
    <!-- <div>底部地基</div> -->
    <div class="wrap-bottom"></div>
    <van-overlay :show="show" />
  </div>
</template>
<script>
import { ready } from "@/utils/wxutils.js";
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      abc: 0,
      show: false,
      production: [],
      spec: "",
      name: "",
      age: "",
      phone: "",
      home: "",
      wish: "",
      price: "",
      pattern: /^((0{1}\.\d{1,2})|([1-9]\d*\.{1}\d{1,2})|([1-9]+\d*))$/,

    };
  },
  created() {
    this.getprod();
  },
  computed: {
    changecolor(abcd) {
      if (abcd == this.abc) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    onSubmit() {
      this.show = true;
      let that = this;
      this.$api.golive
        .generateorder(
          this.productid,
          this.specid,
          this.name,
          this.phone,
          this.wish,
          this.price
        )
        .then((res) => {
          console.log(res);
          if (res.data.status == 1) {
            ready(["chooseWXPay"])
              .then((res2) => {
                console.log(res2);
                wx.chooseWXPay({
                  timestamp: res.data.data.timestamp,
                  nonceStr: res.data.data.nonceStr,
                  package: res.data.data.package,
                  signType: res.data.data.signType,
                  paySign: res.data.data.paySign,
                  success: function () {
                    let orderno = res.data.data.orderno;
                    let retry = 1;
                    let S = setInterval(function () {
                      if (retry > 5) {
                        clearInterval(S);
                        that.$toast.fail("未获取到支付结果");
                        that.show = false;
                        return;
                      } //查询订单是否支付
                      that.$api.golive.ensure(orderno).then((res) => {
                        retry += 1;
                        console.log(res);
                        if (res.data.status == 1) {
                          clearInterval(S);
                          that.show = false;
                          that.$toast.success("支付成功");
                          that.$router.push({
                            path: "/center/golive",
                          });
                        }
                      });
                    }, 2000); //console.log(result);
                  },
                  cancel: function () {
                    that.show = false;
                  },
                });
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
    },
    generate() {
      this.$refs.formData.submit();
    },
    getshareconfig() {
      this.sharepath = window.location.href;
      //let sharepath = "http://h5.allsparksoft.com";
      this.$api.share.getshare(this.sharepath).then((res) => {
        if (res.data.status == 1) {
          console.log(res);

          wx.config({
            debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.data.data.appid, // 必填，公众号的唯一标识，填自己的！
            timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
            nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.data.signature, // 必填，签名，见附录1
            jsApiList: ["chooseWXPay"],
          });
          console.log(res.data.data.domain);
        }
      });
    },
    getid(index) {
      this.abc = index;
      this.specid = this.production[index].id;
      console.log(index);
      this.price = this.production[index].price;
      console.log(this.rightday);
    },
    getprod() {
      this.$api.golive.getgoliveprod().then((res) => {
        this.production = res.data.data.specs;
        this.price = this.production[0].price;
        this.specid = this.production[0].id;
        this.productid = res.data.data.id;
        console.log();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.right {
  &-specs {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 0.4rem;
    padding-right: 0.8rem;
  }
}
.btn {
  z-index: 1;
}
</style>